import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpEventType
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PerformanceInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const start: number = (new Date()).getTime();
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.DownloadProgress) {
                }

                if (event.type === HttpEventType.UploadProgress) {
                }

                if (event.type === HttpEventType.Response) {
                    const end: number = (new Date()).getTime();
                    const total = end - start;
                    if (total > 4000) console.warn(`[PerformanceInterceptor][${total}ms]`, request.urlWithParams);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const end: number = (new Date()).getTime();
                const total = end - start;
                if (total > 4000) console.warn(`[PerformanceInterceptor][${total}ms]`, request.urlWithParams);
                return throwError(error);
            })
        );
    }
}