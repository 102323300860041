import { Component, SimpleChanges } from '@angular/core';
import { PrivatePageBase } from '@framework/base/private-base/private-page.basse';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'main-page',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss']
})
export class MainPage extends PrivatePageBase {
  constructor(private titleService: Title){
    super();
  }
  protected onInit(): void {
    //this.titleService.setTitle('');
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }
}
