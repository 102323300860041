import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrandingComponent } from './branding/branding.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { PinnedMenuComponent } from './pinned-menu/pinned-menu.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AsideContainerComponent } from './aside-container/aside-container.component';
import { SharedModule } from '@app/modules/shared/shared.module';

const comps = [
  BrandingComponent,
  UserInfoComponent,
  PinnedMenuComponent,
  NavigationComponent,
  AsideContainerComponent
]

@NgModule({
  declarations: comps,
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: comps
})
export class ComponentModule { }
