import { SharedModule } from '@app/modules/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as pages from './pages';
import { RootStateModule } from '@app/state/rootstate.module';
import { AuthGuard } from '../auth/guards/auth.guard';
import { AuthService } from '../auth/services/auth.service';
import { ComponentModule } from './components/component.module';

const routes: Routes = [
    {
        path: '',
        component: pages.LayoutPage,
        children: [
            {
                path: 'auth',
                component: pages.PublicPage,
                loadChildren: '../auth/auth.module#AuthModule'
            },
            {
                path: 'policy',
                component: pages.PublicPage,
                loadChildren: '../policy/policy.module#PolicyModule'
            },
            {
                path: '',
                component: pages.MainPage,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: '../dashboard/dashboard.module#DashboardModule'
                    },
                    {
                        path: 'dashboard',
                        loadChildren: '../dashboard/dashboard.module#DashboardModule'
                    }
                ]
            },
            
            // {
            //     path: '**',
            //     component: pages.LayoutPage,
            // }
        ]
    }
];

@NgModule({
    declarations: [
        pages.LayoutPage,
        pages.MainPage,
        pages.PublicPage
    ],
    imports: [
        RouterModule.forRoot(routes),
        RootStateModule.forRoot(),
        SharedModule.forRoot(),
        ComponentModule
    ],
    exports: [
        RouterModule
    ]
})
export class CoreRoutingModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreRoutingModule,
            providers: [AuthGuard, AuthService]
        }
    }
}