import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from '@framework/base/components/component/component.base';

@Component({
  selector: 'button-control',
  templateUrl: './button-control.component.html',
  styleUrls: ['./button-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonControlComponent extends ComponentBase {
  public defaultCss = 'btn btn-primary';

  @Input('type')
  type: string = 'button';

  @Input('css')
  css: string = '';

  @Output()
  onClicked: EventEmitter<any> = new EventEmitter<any>();


  constructor(){
    super();
  }
  
  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }

  private initCss() {
    this.defaultCss += ` ${this.css}`;
  }

  public clicked($event: any) {
    this.onClicked.emit($event);
  }
}
