import { Component } from '@angular/core';
import { InputDatetimeControlComponent } from '../input-datetime-control/input-datetime-control.component';

@Component({
  selector: 'app-input-datetime-range-control',
  templateUrl: './input-datetime-range-control.component.html',
  styleUrls: ['./input-datetime-range-control.component.scss']
})
export class InputDatetimeRangeControlComponent {
}
