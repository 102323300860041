import { Component, OnInit } from '@angular/core';
import { InputTextControlComponent } from '../input-text-control/input-text-control.component';

@Component({
  selector: 'app-input-url-control',
  templateUrl: './input-url-control.component.html',
  styleUrls: ['./input-url-control.component.scss']
})
export class InputUrlControlComponent {

  

}
