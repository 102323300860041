import { Component, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { InputControlBase } from '../base/input-control-base/input-control.base';

@Component({
  selector: 'input-password-control',
  templateUrl: './input-password-control.component.html',
  styleUrls: ['./input-password-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputPasswordControlComponent extends InputControlBase<string> {
  constructor(){
    super();
  }
  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {    
  }

  private initCss() {
    this.defaultCss += ` ${this.css}`;
  }
}
