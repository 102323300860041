import { createReducer, Action, on } from '@ngrx/store';
import { AuthState, initialState } from '../state/auth.state';
import { authActions } from '../actions';

const reducer = createReducer(
    initialState,
    on(authActions.loginSuccess, (state: AuthState, { output }) => {
        return ({
            ...state,
            userContext: {
                ...output.userContext
            },
            isAuthenticated: true
        });
    }),
    on(authActions.loginFail, (state: AuthState, { input }) => {

        return state;
    })
);

export function authReducer(state: AuthState, action: Action) {
    return reducer(state, action);
}