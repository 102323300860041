import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ComponentControlBase } from '@framework/base/components/control/component-control.base';

@Component({
  selector: 'input-checkbox-control',
  templateUrl: './input-checkbox-control.component.html',
  styleUrls: ['./input-checkbox-control.component.scss']
})
export class InputCheckboxControlComponent extends ComponentControlBase {
  public defaultCss = 'form-check-input';

  @Input('css')
  css: string = '';

  constructor(){
    super();
  }
  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }

  private initCss() {
    this.defaultCss += ` ${this.css}`;
  }
  
}
