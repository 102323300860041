import { Component, SimpleChanges } from '@angular/core';
import { PublicPageBase } from '@framework/base/public-page/public-page.base';

@Component({
  selector: 'public-page',
  templateUrl: './public.page.html',
  styleUrls: ['./public.page.scss']
})
export class PublicPage extends PublicPageBase {
  protected onInit(): void {
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }
}
