import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '@app/modules/auth/services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    

    if (this.authService.isAuthenticated()) {
      console.log('[TokenInterceptor]', request);
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${this.authService.getToken()}`) });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse)=>{
        if (this.authService.isAuthenticated()) {
          console.error('[TokenInterceptor]', error);
        }
        return throwError(error);
      })
    );
  }

}