export class AuthorizedUserModel {
    public uuid: string;
    public email: string;
    public avatar: string;
    public providerId: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public token: string;


    constructor(init?: Partial<AuthorizedUserModel>) {
        Object.assign(this, init);
    }
}