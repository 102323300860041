import { Component, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { ComponentBase } from 'framework/base/components/component/component.base';

@Component({
  selector: 'scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScrollbarComponent extends ComponentBase {
  protected onInit(): void {
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }


  

}
