import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  imports: [
    CommonModule
  ]
})
export class Auth0Module {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: Auth0Module,
      providers: []
    }
  }
}
