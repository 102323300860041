import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreStateModule } from './core/core-state.module';


@NgModule({
  imports: [
    CommonModule,
    CoreStateModule.forRoot()
  ]
})

export class RootStateModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootStateModule,
      providers: []
    }
  }
}
