
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrameworkModule } from '@framework/framework.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { GlobalInterceptor } from './interceptors/global.interceptor';
import { PerformanceInterceptor } from './interceptors/performance.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    FrameworkModule.forRoot()
  ],
  exports: [
    FrameworkModule
  ],
  providers: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GlobalInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PerformanceInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

        
      ]
    }
  }
  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}
