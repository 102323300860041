import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services';
import { tap, map } from 'rxjs/operators';
import { environment } from '@app/env/environment';


@Injectable()
export class AuthGuard implements CanActivate {
    private authEnv = environment.auth;
    
    constructor(private authService: AuthService, private router: Router){
        // console.log(this.router);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isAuthenticated()) return true;
        return this.authService.isAuthenticatedState().pipe(
            map((isAuthenticated: boolean) => {
                return isAuthenticated;
            }),
            tap((isAuthenticated: boolean) => {
                if (isAuthenticated === false) this.router.navigate([this.authEnv.login], { queryParams: { returnUrl: state.url }});
            })
        );
    }
}