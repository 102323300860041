
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MvcModule } from './mvc/mvc.module';
import { FirebaseModule } from './cloud/firebase/firebase.module';
import { Auth0Module } from './cloud/auth0/auth0.module';



@NgModule({
    declarations: [],
    imports: [
        FirebaseModule.forRoot(),
        Auth0Module.forRoot(),
        MvcModule.forRoot(),
    ],
    exports: [
        MvcModule
    ],
    providers: []
})
export class FrameworkModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: FrameworkModule,
            providers: [
                
                
            ]
        }
    }
}