import { Component, OnInit } from '@angular/core';
import { InputTextControlComponent } from '../input-text-control/input-text-control.component';

@Component({
  selector: 'app-input-text-multiline-control',
  templateUrl: './input-text-multiline-control.component.html',
  styleUrls: ['./input-text-multiline-control.component.scss']
})
export class InputTextMultilineControlComponent {

  

}
