import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpEventType
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('[GlobalInterceptor]', request);
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.DownloadProgress) {
                }

                if (event.type === HttpEventType.UploadProgress) {
                }

                if (event.type === HttpEventType.Response) {
                    console.log('[GlobalInterceptor]',event.body);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('[GlobalInterceptor]', error)
                return throwError(error);
            })
        );
    }
}