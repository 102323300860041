import { createReducer, Action } from '@ngrx/store';
import { CoreState, initialState } from '../state/core.state';

const reducer = createReducer(
    initialState,
    
);

export function coreReducer(state: CoreState, action: Action) {
    return reducer(state, action);
}