import { RootState } from '@app/state/core/state/root.state';
import { UserContextModel } from '@app/modules/shared/models/auth-models';

export interface AuthState {
    userContext: UserContextModel,
    isAuthenticated: boolean
}

export const initialState: AuthState = {
    userContext: null,
    isAuthenticated: false
}

export interface State extends RootState {
    auth: AuthState
}