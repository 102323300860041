import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuthenticationService } from '@framework/cloud/firebase/services/angular-fire-authentication.service';
import { AuthorizedUserModel } from '@framework/models/users/authorized-user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { RootState } from '@app/state/core/state/root.state';
import { UserContextModel } from '@app/modules/shared/models/auth-models';
import { LoginOutputApiModel } from '@app/modules/shared/models/api-models/output';
import * as authActions from '@app/state/auth/actions';

@Injectable()
export class AuthService {

  private currentUser: UserContextModel = null;
  private authorizedUser: AuthorizedUserModel = null;

  constructor(private firebaseAuthService: AngularFireAuthenticationService,
              private store$: Store<RootState>,
              private http: HttpClient) {

  }

  private async setCurrentUser() {
    this.authorizedUser = await this.firebaseAuthService.getAuthorizedUser();
    this.currentUser = new UserContextModel(this.authorizedUser);
    return this.currentUser;
  }

  public async getCurrentUser() {
    return this.currentUser;
  }

  public getToken(): string {
    return this.isAuthenticated() ? this.currentUser.token : ''; 
  }

  public isAuthenticated(): boolean {
    const isAuthenticated = this.currentUser !== null;
    return isAuthenticated;
  }

  public isAuthenticatedState(): Observable<boolean> {
    return this.firebaseAuthService.getAuthorizedUserState().pipe(
      map((authorizedUser: AuthorizedUserModel) => {
        const isAuthenticated = authorizedUser !== null;
        if (isAuthenticated) {
          this.currentUser = new UserContextModel(authorizedUser);
          this.updateAuthState();
        }
        return isAuthenticated;
      })
    );
  }

  private updateAuthState() {
    const output = new LoginOutputApiModel({
      userContext: new UserContextModel(this.currentUser)
    })
    this.store$.dispatch(authActions.authActions.loginSuccess({output}));
  }

  public async login() {

  }

  public async logout() {
    await this.firebaseAuthService.logout();
    this.currentUser = null;
  }

  public async loginWithGoogle() {
    await this.firebaseAuthService.loginWithGoogle();
    await this.setCurrentUser();
    return this.currentUser;
  }

  public async loginWithFacebook() {
    await this.firebaseAuthService.loginWithFacebook();
    await this.setCurrentUser();
  }

  public async loginWithTwitter() {
    await this.firebaseAuthService.loginWithTwitter();
    await this.setCurrentUser();
  }

  public async loginWithMicrosoft() {
    await this.firebaseAuthService.loginWithMicrosoft();
    await this.setCurrentUser();
  }
}
