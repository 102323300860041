import { createAction, props } from '@ngrx/store';
import { LoginGoogleInputApiModel, LoginFacebookInputApiModel, LoginTwitterInputApiModel, LoginMicrosoftInputApiModel, LoginInputApiModel, LogoutInputApiModel } from '@app/modules/shared/models/api-models/input';
import { LoginGoogleOutputApiModel, LoginFacebookOutputApiModel, LoginTwitterOutputApiModel, LoginMicrosoftOutputApiModel, LoginOutputApiModel, LogoutOutputApiModel } from '@app/modules/shared/models/api-models/output';


export const login = createAction(
    '[User] User Login',
    props<{input: LoginInputApiModel}>()
);

export const loginSuccess = createAction(
    '[User] User Login Success',
    props<{output: LoginOutputApiModel}>()
);

export const loginFail = createAction(
    '[User] User Login Fail',
    props<{input: LoginInputApiModel, error: any}>()
);

export const loginGoogle = createAction(
    '[User] User Login Google',
    props<{input: LoginGoogleInputApiModel}>()
);

export const loginGoogleSuccess = createAction(
    '[User] User Login Google Success',
    props<{output: LoginGoogleOutputApiModel}>()
);

export const loginGoogleFail = createAction(
    '[User] User Login Google Fail',
    props<{input: LoginGoogleInputApiModel, error: any}>()
);

export const loginFacebook = createAction(
    '[User] User Login Facebook',
    props<{input: LoginFacebookInputApiModel}>()
);

export const loginFacebookSuccess = createAction(
    '[User] User Login Facebook Success',
    props<{output: LoginFacebookOutputApiModel}>()
);

export const loginFacebookFail = createAction(
    '[User] User Login Facebook Fail',
    props<{input: LoginFacebookInputApiModel, error: any}>()
);

export const loginTwitter = createAction(
    '[User] User Login Twitter',
    props<{input: LoginTwitterInputApiModel}>()
);

export const loginTwitterSuccess = createAction(
    '[User] User Login Twitter Success',
    props<{output: LoginTwitterOutputApiModel}>()
);

export const loginTwitterFail = createAction(
    '[User] User Login Twitter Fail',
    props<{input: LoginTwitterInputApiModel, error: any}>()
);

export const loginMicrosoft = createAction(
    '[User] User Login Micosoft',
    props<{input: LoginMicrosoftInputApiModel}>()
);

export const loginMicrosoftSuccess = createAction(
    '[User] User Login Micosoft Success',
    props<{output: LoginMicrosoftOutputApiModel}>()
);

export const loginMicrosoftFail = createAction(
    '[User] User Login Micosoft Fail',
    props<{input: LoginMicrosoftInputApiModel, error: any}>()
);

export const logout = createAction(
    '[User] User Logout',
    props<{input: LogoutInputApiModel}>()
);

export const logoutSuccess = createAction(
    '[User] User Logout Success',
    props<{output: LogoutOutputApiModel}>()
);

export const logoutFail = createAction(
    '[User] User Logout Fail',
    props<{input: LogoutInputApiModel, error: any}>()
);