// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  assets: 'assets/images',
  cloud: {
    firebase: {
      apiKey: "AIzaSyCalWzKSvIZ0EsUDbWwWdOR9-gsJy7jVB4",
      authDomain: "intransportation.firebaseapp.com",
      databaseURL: "https://intransportation.firebaseio.com",
      projectId: "intransportation",
      storageBucket: "intransportation.appspot.com",
      messagingSenderId: "729068665942"
    },
    auth0: {
      domain: "intransportation.auth0.com",
      client_id: "073F1BRCbcoWe7Jui81vatOiBsQtQY1g",
      redirect_uri: `${window.location.origin}/auth/callback`
    }
  },
  auth: {
    login: '/auth/login',
    redirectDefault: '/auth/authorized' 
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
