import { Component, SimpleChanges, ViewEncapsulation, Input } from '@angular/core';
import { ComponentControlBase } from 'framework/base/components/control/component-control.base';

@Component({
  selector: 'label-control',
  templateUrl: './label-control.component.html',
  styleUrls: ['./label-control.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LabelControlComponent extends ComponentControlBase {

  public defaultCss: string = 'col-form-label col-form-label-sm';
  
  @Input('text')
  text: string = '';
  
  constructor() {
    super();
  }

  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {    
  }

  private initCss() {
    //this.defaultCss += ` ${this.css}`;
  }
}
