import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ComponentSidebarBase } from 'framework/base/components/sidebar/component-sidebar.base';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends ComponentSidebarBase {
  protected onInit(): void {
    
  }  
  protected onDestroy(): void {
    
  }
  protected onChanges(changes: SimpleChanges): void {
    
  }
  protected onDoCheck(): void {
    
  }
  protected onAfterContentInit(): void {
    
  }
  protected onAfterContentChecked(): void {
    
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }


}
