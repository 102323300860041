import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import * as authEffects from './effects';
import * as authReducers from './reducers';

@NgModule({
    imports: [
        CommonModule,

        StoreModule.forFeature('auth', authReducers.authReducer),

        EffectsModule.forFeature([
            authEffects.AuthEffects
        ])
    ]
})
export class AuthStateModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthStateModule,
            providers: []
        }
    }
}