import { RootState } from './root.state';
export interface CoreState {
}

export const initialState: CoreState = {
    
}

export interface State extends RootState {
    core: CoreState
}

