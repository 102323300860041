import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { CoreApp } from './core.component';



@NgModule({
  declarations: [
    CoreApp
  ],
  imports: [
    CommonModule,
    CoreRoutingModule.forRoot()
  ],
  exports: [
    CoreRoutingModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: []
    }
  }
}
