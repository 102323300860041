import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from '../actions';
import { map, switchMap, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@app/modules/auth/services';
import { from, of } from 'rxjs';
import { 
  LoginGoogleOutputApiModel, 
  LoginFacebookOutputApiModel, 
  LoginOutputApiModel, 
  LoginTwitterOutputApiModel, 
  LoginMicrosoftOutputApiModel,
  LogoutOutputApiModel
} from '@app/modules/shared/models/api-models/output';
import { UserContextModel } from '@app/modules/shared/models/auth-models';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@app/env/environment';
import { RootState } from '@app/state/core/state/root.state';
import { Store } from '@ngrx/store';


@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions,
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private store$: Store<RootState>) {}
  
  login$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({input}) => {
        
        return from(this.authService.login()).pipe(
          map(()=> {
            const output: LoginOutputApiModel = new LoginOutputApiModel();
            return authActions.loginSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.loginFail({input, error}));
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      withLatestFrom(this.store$.select(state => state.router)),
      tap(([{output}, route]) => {
        let returnUrl = route.state.url;
        if (this.route.snapshot.queryParams['returnUrl']) {
          returnUrl = this.route.snapshot.queryParams['returnUrl'];
        }
        this.router.navigate([environment.auth.redirectDefault], { queryParams: { returnUrl: returnUrl }});
      })
    )
  ,{dispatch: false});

  loginGoogle$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginGoogle),
      switchMap(({input}) => {
        
        return from(this.authService.loginWithGoogle()).pipe(
          map((userContext: UserContextModel)=> {
            const output: LoginGoogleOutputApiModel = new LoginGoogleOutputApiModel({
              userContext: userContext
            });
            return authActions.loginGoogleSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.loginGoogleFail({input, error}));
          })
        );
      })
    )
  );

  loginGoogleSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginGoogleSuccess),
      map(({output}) => { 
        return authActions.loginSuccess({output});
      })
    )
  );


  loginFacebook$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginFacebook),
      switchMap(({input}) => {
        
        return from(this.authService.loginWithFacebook()).pipe(
          map(()=> {
            const output: LoginFacebookOutputApiModel = new LoginFacebookOutputApiModel();
            return authActions.loginFacebookSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.loginFacebookFail({input, error}));
          })
        );
      })
    )
  );

  loginFacebookSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginFacebookSuccess),
      map(({output}) => { 
        return authActions.loginSuccess({output});
      })
    )
  );

  loginTwitter$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginTwitter),
      switchMap(({input}) => {
        
        return from(this.authService.loginWithTwitter()).pipe(
          map(()=> {
            const output: LoginTwitterOutputApiModel = new LoginTwitterOutputApiModel();
            return authActions.loginTwitterSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.loginTwitterFail({input, error}));
          })
        );
      })
    )
  );

  loginTwitterSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginTwitterSuccess),
      map(({output}) => { 
        return authActions.loginSuccess({output});
      })
    )
  );

  loginMicrosoft$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginMicrosoft),
      switchMap(({input}) => {
        
        return from(this.authService.loginWithMicrosoft()).pipe(
          map(()=> {
            const output: LoginMicrosoftOutputApiModel = new LoginMicrosoftOutputApiModel();
            return authActions.loginMicrosoftSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.loginMicrosoftFail({input, error}));
          })
        );
      })
    )
  );

  loginMicrosoftSuccess$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.loginMicrosoftSuccess),
      map(({output}) => { 
        return authActions.loginSuccess({output});
      })
    )
  );

  loyout$ = createEffect(() => 
    this.actions$.pipe(
      ofType(authActions.logout),
      switchMap(({input}) => {
        
        return from(this.authService.logout()).pipe(
          map(()=> {
            const output: LogoutOutputApiModel = new LogoutOutputApiModel();
            return authActions.logoutSuccess({output});
          }),
          catchError((error: any) => {
            return of(authActions.logoutFail({input, error}));
          })
        );
      })
    )
  );
}
