import { ModelBase } from '@framework/models/base/base.model';
export class UserContextModel extends ModelBase {
    public uuid: string;
    public email: string;
    public avatar: string;
    public providerId: string;
    public firstName: string;
    public lastName: string;
    public fullName: string;
    public token: string;
    constructor(init?: Partial<UserContextModel>){
        super(init);
        Object.assign(this, init);
    }
}