import { Component, SimpleChanges } from '@angular/core';
import { ComponentBase } from '@framework/base/components/component/component.base';

@Component({
  selector: 'aside-container',
  templateUrl: './aside-container.component.html',
  styleUrls: ['./aside-container.component.scss']
})
export class AsideContainerComponent extends ComponentBase {
  protected onInit(): void {
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {
  }


  

}
