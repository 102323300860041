import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { combineLatest, Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthorizedUserModel } from '@framework/models/users/authorized-user.model';

@Injectable()
export class AngularFireAuthenticationService {
    private facebookProvider: firebase.auth.FacebookAuthProvider;
    private googleProvider: firebase.auth.GoogleAuthProvider;
    private twitterProvider: firebase.auth.TwitterAuthProvider;
    private microsoftProvider: firebase.auth.OAuthProvider;

    private authorizedUserModel: AuthorizedUserModel = null;

    constructor(private fireAuth: AngularFireAuth) {
        this.init();
    }

    private init() {
        this.facebookProvider = new firebase.auth.FacebookAuthProvider();
        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        this.twitterProvider = new firebase.auth.TwitterAuthProvider();
        this.microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');

        this.subscribe();
    }

    private subscribe() {
        this.getAuthState().pipe(
            tap(this.authorizedCallback.bind(this))
        );
    }

    private authorizedCallback(authorizedData: [firebase.User, firebase.auth.IdTokenResult]) {
        if (authorizedData && authorizedData.length) {
            if (authorizedData[0] && authorizedData[0].providerData.length) {
                if (authorizedData[1] && authorizedData[1].signInProvider === 'google.com') {
                    this.authorizedGoogle(authorizedData[0].providerData[0], authorizedData[1]);

                }

                if (authorizedData[1] && authorizedData[1].signInProvider === 'facebook.com') {
                    this.authorizedFacebook(authorizedData[0].providerData[0], authorizedData[1]);

                }

                if (authorizedData[1] && authorizedData[1].signInProvider === 'twitter.com') {
                    this.authorizedTwitter(authorizedData[0].providerData[0], authorizedData[1]);
                }

                if (authorizedData[1] && authorizedData[1].signInProvider === 'microsoft.com') {
                    this.authorizedMicrosoft(authorizedData[0].providerData[0], authorizedData[1]);
                }
            }
        }
    }

    private getAuthState(): Observable<[firebase.User, firebase.auth.IdTokenResult]> {
        return combineLatest(this.fireAuth.authState, this.fireAuth.idTokenResult);
    }

    private authorizedGoogle(userInfo: firebase.UserInfo, idTokenResult: firebase.auth.IdTokenResult) {
        this.authorizedUserModel = new AuthorizedUserModel(
            {
                uuid: userInfo.uid,
                email: userInfo.email,
                fullName: userInfo.displayName,
                avatar: userInfo.photoURL,
                providerId: idTokenResult.signInProvider,
                token: idTokenResult.token
            }
        );
    }

    private authorizedFacebook(userInfo: firebase.UserInfo, idTokenResult: firebase.auth.IdTokenResult) {
        this.authorizedUserModel = new AuthorizedUserModel(
            {
                uuid: userInfo.uid,
                email: userInfo.email,
                fullName: userInfo.displayName,
                avatar: userInfo.photoURL,
                providerId: idTokenResult.signInProvider,
                token: idTokenResult.token
            }
        );
    }

    private authorizedTwitter(userInfo: firebase.UserInfo, idTokenResult: firebase.auth.IdTokenResult) {
        this.authorizedUserModel = new AuthorizedUserModel(
            {
                uuid: userInfo.uid,
                email: userInfo.email,
                fullName: userInfo.displayName,
                avatar: userInfo.photoURL,
                providerId: idTokenResult.signInProvider,
                token: idTokenResult.token
            }
        );
    }

    private authorizedMicrosoft(userInfo: firebase.UserInfo, idTokenResult: firebase.auth.IdTokenResult) {
        this.authorizedUserModel = new AuthorizedUserModel(
            {
                uuid: userInfo.uid,
                email: userInfo.email,
                fullName: userInfo.displayName,
                avatar: userInfo.photoURL,
                providerId: idTokenResult.signInProvider,
                token: idTokenResult.token
            }
        );
    }

    public getAuthorizedUser(): Promise<AuthorizedUserModel> {
        return new Promise<AuthorizedUserModel>((resolve, reject) => {
            resolve(this.authorizedUserModel);
        });
    }

    public getAuthorizedUserState(): Observable<AuthorizedUserModel> {
        return this.getAuthState().pipe(
            map((authorizedData: [firebase.User, firebase.auth.IdTokenResult]) => {
                this.authorizedCallback(authorizedData);
                return this.authorizedUserModel;
            })
        );
    }

    public loginWithFacebook() {
        this.facebookProvider.addScope('email');
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth
                .signInWithPopup(this.facebookProvider)
                .then((res: firebase.auth.UserCredential) => {
                    resolve(res);
                }, (error: any) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    public loginWithGoogle() {
        this.googleProvider.addScope('email');
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth
                .signInWithPopup(this.googleProvider)
                .then((res: firebase.auth.UserCredential) => {
                    resolve(res);
                }, (error: any) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    public loginWithTwitter() {
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth
                .signInWithPopup(this.twitterProvider)
                .then((res: firebase.auth.UserCredential) => {
                    resolve(res);
                }, (error: any) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    public loginWithMicrosoft() {
        this.microsoftProvider.addScope('User.Read');
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth
                .signInWithPopup(this.microsoftProvider)
                .then((res: firebase.auth.UserCredential) => {
                    resolve(res);
                }, (error: any) => {
                    console.log(error);
                    reject(error);
                });
        });
    }

    public logout() {
        return new Promise<any>((resolve, reject) => {
            this.fireAuth.auth.signOut().then(() => {
                this.getAuthorizedUser = null;
                resolve();
            }, (error: any) => {
                console.log(error);
                reject(error);
            });
        });
    }
}