import { ComponentControlBase } from '@framework/base/components/control/component-control.base';
import { ControlValueAccessor } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';

export abstract class InputControlBase<T> extends ComponentControlBase implements ControlValueAccessor {
    public defaultCss: string = 'form-control';

    public disabled: boolean;
    protected onChangeFn: any = (_: any) => {};
    protected onTouchedFn: any = (_: any) => {};

    @Input('css')
    css: string = '';
    
    @Input('placeholder')
    placeholder: string = '';

    @Input('value')
    value: T;

    @Output('onChanged')
    onChanged: EventEmitter<any> = new EventEmitter<any>();

    @Output('onEnterKeyup')
    onEnterKeyup: EventEmitter<any> = new EventEmitter<any>();

    @Output('onEscapeKeyup')
    onEscapeKeyup: EventEmitter<any> = new EventEmitter<any>();

    constructor(){
        super();
    }


    writeValue(obj: T): void {
        this.value = obj;
    }
    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
} 