import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxBootstrapModule } from './../libs/ngx-bootstrap/ngx-bootstrap.module';

import * as comps from './components';

@NgModule({
  declarations: [
    comps.ScrollbarComponent,
    comps.InputDatetimeControlComponent,
    comps.InputDatetimeRangeControlComponent,
    comps.InputEmailControlComponent,
    comps.InputNumberControlComponent,
    comps.InputPasswordControlComponent,
    comps.InputTextControlComponent,
    comps.InputTextMultilineControlComponent,
    comps.InputUrlControlComponent,
    comps.LabelControlComponent,
    comps.ModalComponent,
    comps.ModalContainerComponent,
    comps.SelectControlComponent,
    comps.SidebarComponent,
    comps.InputCheckboxControlComponent,
    comps.ButtonControlComponent,
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    NgxBootstrapModule,
  ],
  exports: [
    comps.ScrollbarComponent,
    comps.InputDatetimeControlComponent,
    comps.InputDatetimeRangeControlComponent,
    comps.InputEmailControlComponent,
    comps.InputNumberControlComponent,
    comps.InputPasswordControlComponent,
    comps.InputTextControlComponent,
    comps.InputTextMultilineControlComponent,
    comps.InputUrlControlComponent,
    comps.LabelControlComponent,
    comps.ModalComponent,
    comps.ModalContainerComponent,
    comps.SelectControlComponent,
    comps.SidebarComponent,
    comps.InputCheckboxControlComponent,
    comps.ButtonControlComponent,
  ],
  providers: []
})
export class MvcModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MvcModule,
      providers: [  ]
    }
  }
}
